const myData = {
  'salt': {
    width: 410,
    height: 410,
    xPos: 265,
    yPos: 143,
    mainImage: 'sal-fin_mgvdaa',
    logoImage: 'logo_yku0b6',
    radius: 'max',
  },
  'akp': {
    width: 424,
    height: 424,
    xPos: 293,
    yPos: -257,
    mainImage: 'akp_qaur2j',
    logoImage: 'logo_yku0b6',
    radius: 'max',
  },
  'shift': {
    width: 294,
    height: 294,
    xPos: 19,
    yPos: -241,
    mainImage: 'paradigm-shift_wpaogg',
    logoImage: 'logo_yku0b6',
    radius: 'max',
  },
  'cw2021': {
    width: 330,
    height: 330,
    xPos: -140,
    yPos: -148,
    mainImage: 'cw2021_ynausc',
    logoImage: 'wgc-logo',
    radius: 'max',
  },
  'lift': {
    width: 528,
    height: 528,
    xPos: 0,
    yPos: 0,
    mainImage: 'lift_zgjqvp',
    logoImage: 'plain_pw7uoh',
    radius: 'max',
  },
  'kdg2022': {
    width: 340,
    height: 340,
    xPos: 0,
    yPos: -40,
    mainImage: 'kdg-2022',
    logoImage: 'wgc-logo',
    radius: 'max',
  },
  'lucc2022': {
    width: 448,
    height: 448,
    xPos: -1,
    yPos: 115,
    mainImage: 'lucc2022_nxttnm',
    logoImage: 'plain_pw7uoh',
    radius: 'max',
  },
  'focp2022': {
    width: 380,
    height: 380,
    xPos: -265,
    yPos: 134,
    mainImage: 'focp2022_yxxnni',
    logoImage: 'wgc-logo',
    radius: 'max',
  },
  'all-london': {
    width: 340,
    height: 340,
    xPos: 256,
    yPos: 45,
    mainImage: 'alllondon',
    logoImage: 'plain_pw7uoh',
    radius: 'max',
  },
  'cw2022': {
    width: 406,
    height: 406,
    xPos: 216,
    yPos: 126,
    mainImage: 'cw2022',
    logoImage: 'wgc-logo',
    radius: 'max',
  },
  'ethanim2022': {
    width: 385,
    height: 385,
    xPos: 115,
    yPos: 132,
    mainImage: 'ethanim22_bqdtrt',
    logoImage: 'plain_pw7uoh',
    radius: 'max',
  },
  'kdg2023': {
    width: 385,
    height: 385,
    xPos: -245,
    yPos: 166,
    mainImage: 'kdg2023',
    logoImage: 'plain_pw7uoh',
    radius: 'max',
  },
  'focp2023': {
    width: 391,
    height: 391,
    xPos: -199,
    yPos: 140,
    mainImage: 'focp-2023_xpby1f',
    logoImage: 'plain_pw7uoh',
    radius: 'max',
  },
  'cw2023': {
    width: 341,
    height: 341,
    xPos: -297,
    yPos: 15,
    mainImage: 'cw2023',
    logoImage: 'plain_pw7uoh',
    radius: 'max',
  },
  'ethanim2023': {
    width: 337,
    height: 337,
    xPos: -180,
    yPos: 138,
    mainImage: 'ethanim-2023',
    logoImage: 'plain_pw7uoh',
    radius: 'max',
  },
  'kdg2024': {
    width: 313,
    height: 313,
    xPos: 165,
    yPos: 158,
    mainImage: 'kdg2024',
    logoImage: 'plain_pw7uoh',
    radius: 'max',
  },
}

export default myData;
